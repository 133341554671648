.imgBtn {
  border: 1px solid #bbb;
  border-radius: 10px;
  width: 90%;
  margin: 15px;
}
.imgBtn .internal {
  display: flex;
  flex-direction: row;
  margin: 0 auto 0 auto;
  max-width: 600px;
}
.imgBtn .info {
  display: flex;
  flex-direction: column;
}
.imgBtn h4 {
  text-align: left;
}
.imgBtn:hover {
  background-color: #aaa;
}
.imgBtn a {
  text-decoration: none;
  color: #000;
}
.imgBtn img {
  vertical-align: middle;
  padding: 10px 50px 10px 10px;
  height: 150px;
}

/* icon buttons */
.iconBtn,
.locationSearch {
  border: 1px solid #bbb;
  border-radius: 10px;
  width: calc(100% - 15px);
  margin: 15px;
  font-size: 0.6em;
  display: flex;
  flex-direction: row;
}
.iconBtn:hover {
  background-color: #aaa;
}
.iconBtn span {
  margin-left: 30px;
}
.iconBtn a {
  text-decoration: none;
  color: #000;
}
.iconBtn img,
.addBtn img,
.locationSearch img {
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  width: 30px;
}

.addBtn {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}
.addBtn:hover {
  opacity: 0.8;
}
.bigBtn {
  background-color: #380064;
  color: #fff;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  padding: 20px 50px 20px 50px;
}
.bigBtn:hover {
  opacity: 0.8;
}
.bigBtn a {
  text-decoration: none;
  color: #fff;
}
