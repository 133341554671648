.modelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.error {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin: 1rem 0;
}

.errorHelp {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 2vw, 1.5rem);
  margin: 0;
}

.searchInput {
  border: none;
  background-color: rgb(233, 233, 233);
  outline: none;
  color: rgb(65, 65, 65);
  padding: 0.75rem;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  margin: 1rem 0;
}

.searchInput::placeholder {
  color: var(--lightgrey);
}

.selectors {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.selectContainer:first-child {
  margin-right: 0.25rem;
}

.selectContainer:last-child {
  margin-left: 0.25rem;
}

.selectTitle {
  font-size: clamp(0.9rem, 1vw, 1.1rem);
  text-align: left;
  margin: 0.25rem 0;
}

.description {
  box-sizing: border-box;
  border-radius: 5px;
  border-color: var(--lightgrey);
  resize: vertical;
  outline: none;
}

.description::placeholder {
  color: var(--lightgrey);
}

.modelContainer {
  filter: drop-shadow(1px 1px 2px var(--lightgrey));
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
}

.model {
  font-weight: 500;
  font-size: clamp(1.2rem, 4vw, 1.8rem);
  margin: 0;
}

.modelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: fit-content;
  width: 100%;
}

.modelHelp {
  width: max-content;
  margin-left: 0.25rem;
  font-size: 0.6rem;
  vertical-align: super;
  color: var(--helperDark);
  font-weight: 600;
  padding-right: 1rem;
}

.inputLabel {
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  align-self: flex-start;
}
