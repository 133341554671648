.container {
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 1rem 0.5rem 1rem;
}

.selected {
  background-color: rgb(238, 238, 238);
}

.imgContainer {
  flex: 1;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}

.infoContainer {
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.priceContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
}

.title {
  color: black;
  font-weight: 500;
  font-size: clamp(0.8rem, -1rem + 8.333333vw, 1.2rem);
  text-align: left;
}

.variation {
  color: var(--lightgrey);
  font-weight: 300;
  font-size: clamp(0.5rem, -1.2rem + 8.333333vw, 1rem);
  text-align: left;
}

.price {
  color: var(--main-brand-dark);
  font-weight: 500;
  font-size: clamp(1rem, -0.875rem + 8.333333vw, 1.5rem);
  padding: 0 0.5rem;
}

.originalPrice {
  font-size: clamp(0.5rem, -1.2rem + 8.333333vw, 1rem);
  color: var(--darkgrey);
  font-weight: 300;
  text-decoration: line-through solid;
  -webkit-text-decoration-line: line-through;
}

.image {
  width: 100%;
  height: auto;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.top {
  margin-bottom: 1rem;
}
