.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.label {
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  align-self: flex-start;
}

.input {
  border: none;
  border-bottom: 2px solid rgb(207, 207, 207);
  padding: 0.5rem 0.25rem;
  color: var(--darkgrey);
  outline: none;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  transition: all 0.5s linear;
}

.input:focus {
  border-bottom: 2px solid var(--darkgrey);
}

.input::placeholder {
  color: #d4d4d4;
}
