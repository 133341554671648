.button {
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  padding: 9px 18px 9px 18px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.disabled {
  background-color: var(--lightgrey) !important;
  color: var(--darkgrey) !important;
}

.button:hover {
  background-color: #48017f;
}

.text {
  color: white;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 500;
}

.icon {
  position: absolute;
  color: white;
  left: 10px;
  font-size: 1.5rem;
}

.defaultButton {
  background-color: var(--main-brand-dark);
  filter: drop-shadow(0px 1px 2px var(--lightgrey));
}

.googleButton {
  background-color: white;
  filter: drop-shadow(0px 1px 2px var(--lightgrey));
}

.googleText {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  color: var(--darkgrey);
}

.facebookButton {
  background-color: #1877f2;
}

.appleButton {
  background-color: black;
}

.smallButton {
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 40px;
  background-color: var(--main-brand-dark);
}

.smallText {
  font-size: clamp(0.5rem, 2vw, 0.8rem);
}
