.container {
  background-color: #d4a9ff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  padding-top: 1rem;
}

.heading {
  color: white;
  filter: drop-shadow(1px 1px 4px var(--darkgrey));
}

.stepContainer {
  display: flex;
  margin: 0.5rem;
}

.image {
  width: 20vw;
  height: 20vw;
  max-width: 100px;
  max-height: 100px;
  flex: 1;
  margin-right: 1rem;
}

.subHeader {
  display: flex;
}

.subHeading {
  color: var(--helperDark);
  font-family: 'Secular One', sans-serif;
}

.mainText {
  color: #606060;
  font-size: clamp(0.8rem, 2vw, 1rem);
  text-align: left;
  margin-top: 1vw;
}

.lozenge {
  display: flex;
  flex-direction: column;
  background-color: var(--helperDark);
  width: 15vw;
  height: 15vw;
  max-width: 60px;
  max-height: 60px;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.lozengeText {
  color: white;
  font-family: 'Secular One', sans-serif;
}

.text {
  font-size: clamp(0.8rem, 2vw, 1rem);
  font-weight: 500;
  margin-top: 0.5rem;
}

.number {
  margin-top: -0.5rem;
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  font-weight: 600;
}
