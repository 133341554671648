@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&display=swap');

* {
  font-family: 'roboto', 'sans-serif';
}

div {
  box-sizing: border-box;
}

h2 {
  margin-bottom: 10px;
}

:root {
  --main-brand-dark: #390164;
  --main-brand-light: #e9cffd;
  --warning-red: #be1717;
  --darkgrey: #707070;
  --lightgrey: #9b9b9b;
  --helperLight: #d4a9ff;
  --helperDark: #71459d;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

abbr {
  text-decoration: none;
}

.App {
  font-size: calc(10px + 2vmin);
  text-align: center;
  background-color: var(--main-brand-light);
  color: var(--main-brand-dark);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-logo {
  margin: 5vmin;
  height: 10vmin;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 800px;
  background-image: url('assets/images/background.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: padding-box;
  flex: 1;
  padding: 2rem 0;
}

.btnBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
}

.btnBlock div {
  margin-bottom: 10px;
}

.pageContainer {
  border-radius: 10px;
  width: 90%;
}

.pageContainerFullWidth {
  border-radius: 0px;
  width: 100%;
}
.estTiming {
  margin: 10px 0 10px 0;
}

hr {
  margin: 10px 0 10px 0;
}

.whiteContainer {
  margin: 0 auto 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 2%;
  background-color: #fff;
  width: 90%;
  filter: drop-shadow(1px 1px 4px var(--lightgrey));
}
/* non-white button footer */
.footerContainer {
  margin: 15px auto 0 auto;
  padding: 2%;
  width: 90%;
}

.repatchSelect {
  padding: 5px;
  border-radius: 10px;
  width: 100%;
  outline: none;
}
/* flexTable makes it's first layer of divs rows, the second layer columns */
.flexTable {
  display: flex;
  flex-direction: column;
  vertical-align: center;
}
.flexTable > * {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  align-items: center;
}
/* Allow the first column to be twice that of the others */
.flexTable.bigLeftCol *:first-child {
  flex: 2;
}
.icon {
  padding: 5px;
  width: 30px;
}
textarea {
  width: 100%;
}

p.small {
  font-size: 0.6em;
  color: #555;
}

p.large {
  font-size: 0.8em;
  color: #555;
}

select {
  border-radius: 100px;
  border: var(--main-brand-dark) 1px solid;
  outline: none;
  padding: 0.5rem 1rem;
  color: var(--helperDark);
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
}
