.userContainer .whiteContainer{
  margin-top: 100px;
}

.userPageHeading {
  padding-top: 25px;
}

.userContainer .profilePic {
  margin: -100px auto 0 auto;
  width: 100px;
  height: 100px;
  background-size:contain;
  background-position: center;
  border-radius: 50px;
}

.userContainer .starRating {
  font-size: 0.8em;
}
