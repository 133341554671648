@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Secular+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&display=swap);
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'roboto', 'sans-serif';
}

div {
  box-sizing: border-box;
}

h2 {
  margin-bottom: 10px;
}

:root {
  --main-brand-dark: #390164;
  --main-brand-light: #e9cffd;
  --warning-red: #be1717;
  --darkgrey: #707070;
  --lightgrey: #9b9b9b;
  --helperLight: #d4a9ff;
  --helperDark: #71459d;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

abbr {
  text-decoration: none;
}

.App {
  font-size: calc(10px + 2vmin);
  text-align: center;
  background-color: #e9cffd;
  background-color: var(--main-brand-light);
  color: #390164;
  color: var(--main-brand-dark);
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-logo {
  margin: 5vmin;
  height: 10vmin;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 800px;
  background-image: url(/static/media/background.caae6c2e.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: padding-box;
  flex: 1 1;
  padding: 2rem 0;
}

.btnBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
}

.btnBlock div {
  margin-bottom: 10px;
}

.pageContainer {
  border-radius: 10px;
  width: 90%;
}

.pageContainerFullWidth {
  border-radius: 0px;
  width: 100%;
}
.estTiming {
  margin: 10px 0 10px 0;
}

hr {
  margin: 10px 0 10px 0;
}

.whiteContainer {
  margin: 0 auto 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 2%;
  background-color: #fff;
  width: 90%;
  -webkit-filter: drop-shadow(1px 1px 4px #9b9b9b);
          filter: drop-shadow(1px 1px 4px #9b9b9b);
  -webkit-filter: drop-shadow(1px 1px 4px var(--lightgrey));
          filter: drop-shadow(1px 1px 4px var(--lightgrey));
}
/* non-white button footer */
.footerContainer {
  margin: 15px auto 0 auto;
  padding: 2%;
  width: 90%;
}

.repatchSelect {
  padding: 5px;
  border-radius: 10px;
  width: 100%;
  outline: none;
}
/* flexTable makes it's first layer of divs rows, the second layer columns */
.flexTable {
  display: flex;
  flex-direction: column;
  vertical-align: center;
}
.flexTable > * {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  align-items: center;
}
/* Allow the first column to be twice that of the others */
.flexTable.bigLeftCol *:first-child {
  flex: 2 1;
}
.icon {
  padding: 5px;
  width: 30px;
}
textarea {
  width: 100%;
}

p.small {
  font-size: 0.6em;
  color: #555;
}

p.large {
  font-size: 0.8em;
  color: #555;
}

select {
  border-radius: 100px;
  border: #390164 1px solid;
  border: var(--main-brand-dark) 1px solid;
  outline: none;
  padding: 0.5rem 1rem;
  color: #71459d;
  color: var(--helperDark);
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
}

.shared_container__2RuR1 {
  background-color: white;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  -webkit-filter: drop-shadow(1px 1px 4px var(--lightgrey));
          filter: drop-shadow(1px 1px 4px var(--lightgrey));
  min-height: 50vh;
  display: flex;
  height: 100%;
}

.shared_row__2zDVn {
  display: flex;
  align-items: center;
}

.shared_error__2wRye {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  margin-top: 20px;
  margin-bottom: 20px;
}
.shared_errorHelp__3x4ye {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin-top: 0;
  margin-bottom: 5px;
}
.shared_errorHelpLarge__1zRKV {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  margin-top: 0;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .shared_container__2RuR1 {
    width: 90%;
  }
}

.Home_background__B2C0N {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.Home_container__1s89M {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  padding: 1rem;
  max-width: 1000px;
}

.Home_leftContainer__ntXG9 {
  margin-right: 0.5rem;
}

.Home_rightContainer__bIG_M {
  margin-left: 0.5rem;
  flex: 1 1;
}

.Home_buttonContainer__72cC6 {
  -webkit-filter: drop-shadow(1px 1px 2px var(--lightgrey));
          filter: drop-shadow(1px 1px 2px var(--lightgrey));
  background-color: white;
  border-radius: 10px;
  margin: 0.5rem 0;
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.Home_buttonContainer__72cC6:hover {
  background-color: rgb(224, 224, 224);
}

.Home_buttonTextContainer__3Mwt1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2 1;
}

.Home_imageContainer__2xfV0 {
  flex: 1 1;
}

.Home_image__2qLHx {
  width: 100%;
  height: auto;
}

.Home_title__1RtlI {
  font-weight: 500;
  font-size: clamp(1.1rem, 2.2vw, 1.5rem);
}

.Home_subtitle__2-2TD {
  color: var(--darkgrey);
  font-weight: 300;
  font-size: clamp(0.9rem, 1.8vw, 1rem);
}

@media screen and (max-width: 1000px) {
  .Home_container__1s89M {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .Home_leftContainer__ntXG9 {
    flex: 1 1;
    margin-right: 0rem;
    order: 1;
    width: 90%;
    max-width: 600px;
  }

  .Home_rightContainer__bIG_M {
    flex: 1 1;
    margin-left: 0rem;
    order: 0;
    width: 90%;
    max-width: 600px;
    margin-bottom: 1rem;
  }
}

.HelperBox_container__xXDjP {
  background-color: #d4a9ff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  padding-top: 1rem;
}

.HelperBox_heading__1Ossl {
  color: white;
  -webkit-filter: drop-shadow(1px 1px 4px var(--darkgrey));
          filter: drop-shadow(1px 1px 4px var(--darkgrey));
}

.HelperBox_stepContainer__3G5q_ {
  display: flex;
  margin: 0.5rem;
}

.HelperBox_image__20yXO {
  width: 20vw;
  height: 20vw;
  max-width: 100px;
  max-height: 100px;
  flex: 1 1;
  margin-right: 1rem;
}

.HelperBox_subHeader__3Rq4R {
  display: flex;
}

.HelperBox_subHeading__1Zel3 {
  color: var(--helperDark);
  font-family: 'Secular One', sans-serif;
}

.HelperBox_mainText__23pt- {
  color: #606060;
  font-size: clamp(0.8rem, 2vw, 1rem);
  text-align: left;
  margin-top: 1vw;
}

.HelperBox_lozenge__Ej6Fp {
  display: flex;
  flex-direction: column;
  background-color: var(--helperDark);
  width: 15vw;
  height: 15vw;
  max-width: 60px;
  max-height: 60px;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.HelperBox_lozengeText__3HnOc {
  color: white;
  font-family: 'Secular One', sans-serif;
}

.HelperBox_text__cBlPv {
  font-size: clamp(0.8rem, 2vw, 1rem);
  font-weight: 500;
  margin-top: 0.5rem;
}

.HelperBox_number__2SXmm {
  margin-top: -0.5rem;
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  font-weight: 600;
}

.PromoCode_promoContainer__-29_o {
  width: 80vw;
  max-width: 900px;
  border-radius: 10px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: all 1s ease-in-out;
  background-color: var(--main-brand-dark);
  color: white;
  padding: 0.5rem;
  max-height: 0;
  height: auto;
}

.PromoCode_visible__HW3Io {
  opacity: 1;
  max-height: 1000px;
}

.PromoCode_icon__1aIYI {
  font-size: 10vw;
  max-width: 50px;
  max-height: 50px;
}

.PromoCode_textContainer__F7brU {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.PromoCode_promoText__2187x {
  flex: 1 1;
  font-size: clamp(1rem, 3vw, 2rem);
}

.PromoCode_codeContainer__wWaDN {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5vw;
}

.PromoCode_copyContainer__1Qlgt {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin: 0.5rem 0;
}

.PromoCode_promoCode__2wg7j {
  font-weight: 600;
  font-size: clamp(1.4rem, 4vw, 2.5rem);
  color: var(--main-brand-dark);
  text-align: center;
  padding: 0.25rem 1rem;
  border-right: 2px solid var(--main-brand-dark);
}

.PromoCode_copyButton__Q_7-y {
  color: var(--main-brand-dark);
  border: none;
  font-size: 1.2rem;
  padding: 0.25rem 1rem;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PromoCode_copyButton__Q_7-y:hover {
  opacity: 0.5;
}

@media screen and (max-width: 500px) {
  .PromoCode_promoContainer__-29_o {
    width: 100%;
    border-radius: 0;
  }
}

.RepatchButton_button__3Bt5X {
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  padding: 9px 18px 9px 18px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.RepatchButton_disabled__ymFtS {
  background-color: var(--lightgrey) !important;
  color: var(--darkgrey) !important;
}

.RepatchButton_button__3Bt5X:hover {
  background-color: #48017f;
}

.RepatchButton_text__1FPCw {
  color: white;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 500;
}

.RepatchButton_icon__p5c3W {
  position: absolute;
  color: white;
  left: 10px;
  font-size: 1.5rem;
}

.RepatchButton_defaultButton__3Qa11 {
  background-color: var(--main-brand-dark);
  -webkit-filter: drop-shadow(0px 1px 2px var(--lightgrey));
          filter: drop-shadow(0px 1px 2px var(--lightgrey));
}

.RepatchButton_googleButton__39QlN {
  background-color: white;
  -webkit-filter: drop-shadow(0px 1px 2px var(--lightgrey));
          filter: drop-shadow(0px 1px 2px var(--lightgrey));
}

.RepatchButton_googleText__R4_A8 {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  color: var(--darkgrey);
}

.RepatchButton_facebookButton__3X_Hm {
  background-color: #1877f2;
}

.RepatchButton_appleButton__21Tjm {
  background-color: black;
}

.RepatchButton_smallButton__3q2af {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 40px;
  background-color: var(--main-brand-dark);
}

.RepatchButton_smallText__2MB-a {
  font-size: clamp(0.5rem, 2vw, 0.8rem);
}

.lds-dual-ring {
  display: inline-block;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  margin: 0 0.25rem;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 3px solid var(--main-brand-dark);
  border-color: var(--main-brand-dark) transparent var(--main-brand-dark)
    transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Auth_container__38MaD {
  background-color: white;
  padding: 1rem;
  width: 80%;
  max-width: 600px;
}

.Auth_title__ahNSX {
  font-size: clamp(1.7rem, 3vw, 2.5rem);
  margin: 1rem 0;
}

.Auth_error__mgYyx {
  color: var(--warning-red);
  font-size: 1rem;
  margin: 1rem 0;
}

.Auth_buttonContainer__2cSys {
  display: flex;
  flex-direction: column;
}

.Auth_loginForm__1EEbC {
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  transition: max-height 0.5s ease-in-out;
}

.Auth_visible__1pc0l {
  max-height: 1000px;
}

.Auth_linkContainer__18snG {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
}

.RepatchInput_container__1dt-r {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.RepatchInput_label__28Qno {
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  align-self: flex-start;
}

.RepatchInput_input__3mAD4 {
  border: none;
  border-bottom: 2px solid rgb(207, 207, 207);
  padding: 0.5rem 0.25rem;
  color: var(--darkgrey);
  outline: none;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  transition: all 0.5s linear;
}

.RepatchInput_input__3mAD4:focus {
  border-bottom: 2px solid var(--darkgrey);
}

.RepatchInput_input__3mAD4::-webkit-input-placeholder {
  color: #d4d4d4;
}

.RepatchInput_input__3mAD4::placeholder {
  color: #d4d4d4;
}

.MyOrders_container__2y47D {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.MyOrders_ticket__2xmfc {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.MyOrders_pageTitle__1Nnlf {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: 'Assistant';
}

.MyOrders_emptyOrdersContainer__33vmY {
  margin: 0.5rem;
  border-bottom: 1px dashed #cea2ef;
  padding-bottom: 1rem;
}

.MyOrders_emptyText__e4xxg {
  font-family: 'Assistant';
  color: rgb(71, 71, 71);
  font-size: clamp(18px, 5vw, 24px);
  margin-bottom: 0.5rem;
}

.MyOrders_emptyTextSub__2EwnG {
  color: rgb(129, 129, 129);
  font-size: clamp(14px, 3vw, 20px);
  margin-bottom: 1rem;
}

.MyOrders_emptyOrdersContainer__33vmY button p {
  font-family: 'Assistant';
  font-weight: 400;
}

.MyOrders_headings__1Y-JT {
  opacity: 1;
}

.MyOrders_ticket__2xmfc:hover {
  opacity: 1;
}

.MyOrders_header__16pGC {
  font-size: clamp(0.7rem, 2vw, 1.1rem);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.MyOrders_text__Obkhs {
  text-decoration: none;
  font-size: clamp(14px, 4vw, 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Assistant';
  margin-bottom: 0.25rem;
}

.MyOrders_orderNumber__2ZZQo {
}

textarea {
  padding: 5px;
  height: 10vh;
}

.LocationSearch_container__2BKwA {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  z-index: 9997;
  border-bottom: 2px solid rgb(207, 207, 207);
}

.LocationSearch_searchIcon__3RFLD {
  height: 25px;
  width: 25px;
  margin: 0 0.25rem;
}

.LocationSearch_inputContainer__3k3zJ {
  display: flex;
  width: 100%;
  align-items: center;
}

.LocationSearch_locationInput__36HBy {
  border: none;
  padding: 0.5rem 0.25rem;
  color: var(--darkgrey);
  outline: none;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  transition: all 0.5s linear;
  width: 100%;
}

.LocationSearch_locationInput__36HBy::-webkit-input-placeholder {
  color: #d4d4d4;
}

.LocationSearch_locationInput__36HBy::placeholder {
  color: #d4d4d4;
}

.LocationSearch_resultContainer__2D3PE {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  background-color: white;
  width: 100%;
  -webkit-filter: drop-shadow(0px 1px 2px var(--lightgrey));
          filter: drop-shadow(0px 1px 2px var(--lightgrey));
  max-height: 40vh;
  overflow-y: scroll;
}

.LocationSearch_locationResult__2eJFS {
  font-size: 0.7rem;
  cursor: pointer;
  margin: 0.5rem 0;
}

.LocationSearch_locationResult__2eJFS:hover {
  text-decoration: underline;
}

.LocationSearch_error__AdkZs {
  font-size: 0.7rem;
  color: var(--warning-red);
  margin-top: 0.5rem;
}

.DevicePage_modelContainer__3yXm8 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.DevicePage_error__2Jx-1 {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin: 1rem 0;
}

.DevicePage_errorHelp__34fk8 {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 2vw, 1.5rem);
  margin: 0;
}

.DevicePage_searchInput__6bEk3 {
  border: none;
  background-color: rgb(233, 233, 233);
  outline: none;
  color: rgb(65, 65, 65);
  padding: 0.75rem;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  margin: 1rem 0;
}

.DevicePage_searchInput__6bEk3::-webkit-input-placeholder {
  color: var(--lightgrey);
}

.DevicePage_searchInput__6bEk3::placeholder {
  color: var(--lightgrey);
}

.DevicePage_selectors__3RSfj {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}

.DevicePage_selectContainer__oYO4W {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.DevicePage_selectContainer__oYO4W:first-child {
  margin-right: 0.25rem;
}

.DevicePage_selectContainer__oYO4W:last-child {
  margin-left: 0.25rem;
}

.DevicePage_selectTitle__2psAX {
  font-size: clamp(0.9rem, 1vw, 1.1rem);
  text-align: left;
  margin: 0.25rem 0;
}

.DevicePage_description__XBX9X {
  box-sizing: border-box;
  border-radius: 5px;
  border-color: var(--lightgrey);
  resize: vertical;
  outline: none;
}

.DevicePage_description__XBX9X::-webkit-input-placeholder {
  color: var(--lightgrey);
}

.DevicePage_description__XBX9X::placeholder {
  color: var(--lightgrey);
}

.DevicePage_modelContainer__3yXm8 {
  -webkit-filter: drop-shadow(1px 1px 2px var(--lightgrey));
          filter: drop-shadow(1px 1px 2px var(--lightgrey));
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
}

.DevicePage_model__16ydd {
  font-weight: 500;
  font-size: clamp(1.2rem, 4vw, 1.8rem);
  margin: 0;
}

.DevicePage_modelHeader__kxixA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 100%;
}

.DevicePage_modelHelp__3h2sd {
  width: -webkit-max-content;
  width: max-content;
  margin-left: 0.25rem;
  font-size: 0.6rem;
  vertical-align: super;
  color: var(--helperDark);
  font-weight: 600;
  padding-right: 1rem;
}

.DevicePage_inputLabel__UCgBQ {
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  align-self: flex-start;
}

.Modal_modal__2a3Jl {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0;
  z-index: 9999;
  max-height: 0;
  overflow: hidden;
  width: 100vw;
}

.Modal_open__1tNtj {
  opacity: 1;
  max-height: 2000px;
  transition: opacity 0.3s ease-in-out;
}

.Modal_modalContent__3VFPB {
  min-width: 200px;
  max-width: 600px;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.Modal_modalHeader__3dqs9 {
  align-self: flex-end;
}

.Modal_modalBody__erckU {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.Modal_closeButton__2wlqN {
  cursor: pointer;
  font-size: clamp(1.5rem, 4vw, 2rem);
}

.starRating {
  font-size: 1.5em;
}

.starRating i {
  position: relative;
  color: #ccc;
}
/* create a half-star by nesting a <b> inside an <i> */
.starRating b {
  color: #380064;
  position: absolute;
  left: 0px;
  width: 50%;
  overflow: hidden;
}
.Tickets_ticketPageHeading__1lWZB {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.Tickets_profileButton__J814O {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 10px;
  border: lightgray 1px solid;
  color: rgb(80, 80, 80);
  cursor: pointer;
  transition: all 0.2s linear;
  font-weight: 500;
}

.Tickets_profileButton__J814O:hover {
  background-color: var(--lightgrey);
}

.Tickets_profilePic__3jYtZ {
  margin: -200px auto 0 auto;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-position: center;
  border-radius: 100px;
}

.Tickets_quotesContainer__3-gOf {
  display: flex;
  flex-direction: column;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-top: 1rem;
}

.Tickets_error__3d-WK {
  color: var(--warning-red);
  font-size: 16px;
  margin: 1rem 0;
}

.Tickets_estimate__3OfpN {
  font-size: clamp(0.8rem, 1.8vw, 1.2rem);
  font-weight: 500;
  margin: 0.5rem 0;
}

.Tickets_buttonContainer__2pQaW {
  display: flex;
  width: 100%;
}

.Quote_container__uCW10 {
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 1rem 0.5rem 1rem;
}

.Quote_selected__e-I48 {
  background-color: rgb(238, 238, 238);
}

.Quote_imgContainer__3nymh {
  flex: 1 1;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}

.Quote_infoContainer__3rG0t {
  flex: 6 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Quote_priceContainer__NW2oO {
  flex: 1 1;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
}

.Quote_title__oCBRL {
  color: black;
  font-weight: 500;
  font-size: clamp(0.8rem, -1rem + 8.333333vw, 1.2rem);
  text-align: left;
}

.Quote_variation__2F_0Y {
  color: var(--lightgrey);
  font-weight: 300;
  font-size: clamp(0.5rem, -1.2rem + 8.333333vw, 1rem);
  text-align: left;
}

.Quote_price__3_n5- {
  color: var(--main-brand-dark);
  font-weight: 500;
  font-size: clamp(1rem, -0.875rem + 8.333333vw, 1.5rem);
  padding: 0 0.5rem;
}

.Quote_originalPrice__3rMzr {
  font-size: clamp(0.5rem, -1.2rem + 8.333333vw, 1rem);
  color: var(--darkgrey);
  font-weight: 300;
  -webkit-text-decoration: line-through solid;
          text-decoration: line-through solid;
  -webkit-text-decoration-line: line-through;
}

.Quote_image__2XhrL {
  width: 100%;
  height: auto;
}

.Quote_row__3iwTv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Quote_column__3ITQj {
  display: flex;
  flex-direction: column;
}

.Quote_top__3WTnd {
  margin-bottom: 1rem;
}

.PromoCodeInput_container__cPI6t {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.PromoCodeInput_discountText__2bhg8 {
  font-size: 20px;
}

.PromoCodeInput_removeIcon__33gNq {
  color: var(--lightgrey);
  cursor: pointer;
  font-size: 20px;
}

.PickupTime_pickupContainer__10q0A {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PickupTime_pickupText__3M0PQ {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PickupTime_calendarContainer__3ZOMd {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PickupTime_timePicker__25CAu {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PickupTime_titleContainer__3g2FX {
  display: flex;
  align-items: center;
}

.react-calendar {
  font-family: 'roboto', 'sans-serif';
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: 400;
  padding: 0;
  text-decoration: none;
}

.react-calendar__tile--now {
  background-color: var(--main-brand-light) !important;
}

.react-calendar__tile--active {
  background-color: var(--main-brand-dark) !important;
  color: white;
  font-weight: 600;
}

.RepatchDropdown_select__SlcbT {
  border-radius: 100px;
  border: var(--main-brand-dark) 2px solid;
  outline: none;
  padding: 0.5rem 1rem;
  color: var(--helperDark);
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
}

.PaymentPage_container__1u35G {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 5px;
}

.PaymentPage_personalContainer__1JlHa {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.PaymentPage_paymentContainer__2-fBB {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.PaymentPage_termsContainer__3bp7f {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 1rem 0;
}

.PaymentPage_paymentForm__33bJH {
  margin: 1rem;
}

.PaymentPage_subtitle__3xKMs {
  font-size: clamp(1rem, 3vw, 2rem);
  margin-bottom: 1rem;
}

.PaymentPage_cardForm__2w0NR {
  margin: 2rem 0;
}

.PaymentPage_error__Ld-Uh {
  font-size: 1rem;
  color: var(--warning-red);
}

.PaymentPage_closedMessage__1y2C4 {
  font-size: clamp(1.2rem, 1.5vw, 1.2rem);
  margin-bottom: 1rem;
}

.Task_container__3noNN {
  width: 90%;
  margin: 0 auto 0 auto;
  height: 100px;
  display: flex;
  flex-direction: row;
  display: flex;
  position: relative;
}

.Task_trackContainer___UXO1 {
  flex: 1 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.Task_infoContainer__Txr0H {
  flex: 7 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 1rem;
}

.Task_track__1C7DJ {
  background-color: darkgray;
  height: 100%;
  width: 3px;
}

.Task_trackComplete__1sFsO {
  background-color: #0e47a1;
}

.Task_lozenge__2t_Xs {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: darkgray;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.Task_current__xyHXL {
  -webkit-animation: Task_pulse-blue__3KV0J 2s infinite;
          animation: Task_pulse-blue__3KV0J 2s infinite;
  background-color: #0e47a1;
}

.Task_complete__JiXTK {
  height: 46px;
  width: 46px;
  padding: 5px;
  background-color: #0e47a1;
}

.Task_checkmark__GU-wk {
  height: 100%;
  width: 100%;
}

.Task_title__w42lP {
  font-size: 0.6em;
  font-weight: bold;
  text-align: start;
}

.Task_subTitle__uklBA {
  font-size: 0.5em;
  text-align: start;
}

.Task_timeEstimate__2bl-c {
  font-weight: bold;
  color: #8b53ff;
  text-align: start;
  font-size: 0.5em;
}

.Task_estimatePrefix__3QGh9 {
  color: var(--main-brand-dark);
  font-weight: 400;
}

.Task_repatched__1Eiae {
  height: 46px;
  width: 46px;
  padding: 5px;
  background-color: green;
}

.Task_linkContainer__1BWfQ {
  margin-top: 0.5rem;
}

@-webkit-keyframes Task_pulse-blue__3KV0J {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 71, 161, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 17px rgba(14, 71, 161, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 71, 161, 0);
  }
}

@keyframes Task_pulse-blue__3KV0J {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 71, 161, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 17px rgba(14, 71, 161, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 71, 161, 0);
  }
}

.trackingContainer .whiteContainer {
  width: 100%;
}

.trackingPageHeading {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.trackingPageHeading h2 {
  font-size: 0.8em;
}
.trackingPageHeading p {
  font-size: 0.7em;
}

.taskList {
  margin-top: 1rem;
}

.buttons_imgBtn__38RAj {
  border: 1px solid #bbb;
  border-radius: 10px;
  width: 90%;
  margin: 15px;
}
.buttons_imgBtn__38RAj .buttons_internal__2l3q_ {
  display: flex;
  flex-direction: row;
  margin: 0 auto 0 auto;
  max-width: 600px;
}
.buttons_imgBtn__38RAj .buttons_info__3TsWi {
  display: flex;
  flex-direction: column;
}
.buttons_imgBtn__38RAj h4 {
  text-align: left;
}
.buttons_imgBtn__38RAj:hover {
  background-color: #aaa;
}
.buttons_imgBtn__38RAj a {
  text-decoration: none;
  color: #000;
}
.buttons_imgBtn__38RAj img {
  vertical-align: middle;
  padding: 10px 50px 10px 10px;
  height: 150px;
}

/* icon buttons */
.buttons_iconBtn__2l3o8,
.buttons_locationSearch__1FV1S {
  border: 1px solid #bbb;
  border-radius: 10px;
  width: calc(100% - 15px);
  margin: 15px;
  font-size: 0.6em;
  display: flex;
  flex-direction: row;
}
.buttons_iconBtn__2l3o8:hover {
  background-color: #aaa;
}
.buttons_iconBtn__2l3o8 span {
  margin-left: 30px;
}
.buttons_iconBtn__2l3o8 a {
  text-decoration: none;
  color: #000;
}
.buttons_iconBtn__2l3o8 img,
.buttons_addBtn__3OlJI img,
.buttons_locationSearch__1FV1S img {
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  width: 30px;
}

.buttons_addBtn__3OlJI {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}
.buttons_addBtn__3OlJI:hover {
  opacity: 0.8;
}
.buttons_bigBtn__1_8QB {
  background-color: #380064;
  color: #fff;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  padding: 20px 50px 20px 50px;
}
.buttons_bigBtn__1_8QB:hover {
  opacity: 0.8;
}
.buttons_bigBtn__1_8QB a {
  text-decoration: none;
  color: #fff;
}

.CustomerPage_container__3doYn {
  background-color: white;
  padding: 1rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  -webkit-filter: drop-shadow(1px 1px 4px var(--lightgrey));
          filter: drop-shadow(1px 1px 4px var(--lightgrey));
}

.CustomerPage_inputLabel__2SYMZ {
  align-self: flex-start;
  text-align: left;
  margin: 1rem 0 1rem 0;
}

.CustomerPage_emailPreferences__fQ_UM {
  display: flex;
  flex-direction: row;
}

.CustomerPage_checkboxContainer__2A9Es {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.CustomerPage_checkbox__3oIIY {
  margin-right: 5px;
}

.CustomerPage_small__2FXqP {
  font-size: clamp(0.6rem, 1vw, 0.8rem);
}

.CustomerPage_error__3rE3i {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .CustomerPage_container__3doYn {
    width: 90%;
  }
}

.userContainer .whiteContainer{
  margin-top: 100px;
}

.userPageHeading {
  padding-top: 25px;
}

.userContainer .profilePic {
  margin: -100px auto 0 auto;
  width: 100px;
  height: 100px;
  background-size:contain;
  background-position: center;
  border-radius: 50px;
}

.userContainer .starRating {
  font-size: 0.8em;
}

.DeviceList_container__1AMw7 {
  margin: 0.5rem 0;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 0.7rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-brand-dark);
}

.DeviceList_manufacturerContainer__ZWTm8 {
}

.DeviceList_manufacturerName__24l1d {
  background-color: rgb(211, 211, 211);
  color: rgb(66, 66, 66);
  padding: 0.5rem 0;
  margin: 0 -0.5rem;
  font-size: clamp(1rem, 1.5vw, 1.1rem);
}

.DeviceList_deviceName__860Jw {
  color: rgb(66, 66, 66);
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid rgb(211, 211, 211);
  padding: 0.7rem 0;
  text-align: left;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
}

.DeviceList_deviceName__860Jw:hover {
  background-color: rgb(236, 236, 236);
}

.DeviceList_deviceName__860Jw:last-child {
  border: none;
}

.Help_background__1i878 {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.Help_container__239Ft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  padding: 1rem;
  max-width: 1000px;
}

.Help_leftContainer__3aMWq {
  margin-right: 0.5rem;
}

.Help_rightContainer__3LUss {
  margin-left: 0.5rem;
  flex: 1 1;
}

.Help_buttonContainer__3IMok {
  -webkit-filter: drop-shadow(1px 1px 2px var(--lightgrey));
          filter: drop-shadow(1px 1px 2px var(--lightgrey));
  background-color: white;
  border-radius: 10px;
  margin: 0.5rem 0;
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.Help_buttonContainer__3IMok:hover {
  background-color: rgb(224, 224, 224);
}

.Help_buttonTextContainer__vWD2p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.Help_image__2prvG {
  width: 30%;
}

.Help_title__3vgXy {
  font-weight: 500;
  font-size: clamp(1.1rem, 2.2vw, 1.5rem);
}

.Help_subtitle__1OO6d {
  color: var(--darkgrey);
  font-weight: 300;
  font-size: clamp(0.9rem, 1.8vw, 1rem);
}

.Help_helpList__3_3wi {
  text-align: left;
  padding-left: 50px;
  font-size: 1.3rem;
}

.Help_helpList__3_3wi li {
  padding-top: 10px;
}

@media screen and (max-width: 1000px) {
  .Help_container__239Ft {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .Help_leftContainer__3aMWq {
    flex: 1 1;
    margin-right: 0rem;
    order: 1;
    width: 90%;
    max-width: 600px;
  }

  .Help_rightContainer__3LUss {
    flex: 1 1;
    margin-left: 0rem;
    order: 0;
    width: 90%;
    max-width: 600px;
    margin-bottom: 1rem;
  }
}

.RepatchFooter_container__3Uv1G {
  padding: 3vh;
  font-size: 0.7em;
  background-color: var(--main-brand-dark);
  color: white;
}

.RepatchFooter_linksContainer__6DFhA {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
}

.RepatchFooter_link__25Ym1 {
  color: white;
  font-weight: 300;
  font-size: clamp(0.7rem, 1.5vw, 1rem);
  margin: 0 0.25rem;
}

.RepatchHeader_container__3z7XC {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  background-color: white;
  z-index: 9998;
  overflow: hidden;
}

.RepatchHeader_logo__1oWVP {
  height: 11vh;
  width: auto;
}

.RepatchHeader_menuLogo__3Cnh6 {
  margin-left: -1rem;
  margin-bottom: 10vh;
}

.RepatchHeader_sideMenu__vxXS5 {
  z-index: 9998;
  height: 100vh;
  width: 100vw;
  top: 0;
  max-width: 400px;
  background-color: white;
  position: fixed;
  right: -100vw;
  -webkit-filter: drop-shadow(0px 1px 2px var(--lightgrey));
          filter: drop-shadow(0px 1px 2px var(--lightgrey));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}

.RepatchHeader_sideMenuOpen__2Q_rR {
  right: 0;
  padding: 0 1rem;
}

.RepatchHeader_menuLink__rpduy {
  margin: 0.5rem 0;
  cursor: pointer;
  text-decoration: none;
}

.RepatchHeader_menu__1K7PR {
  z-index: 9999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* border: 3px solid #fff; */
}
.RepatchHeader_menuBurger__2my2x {
  width: 40px;
  height: 4px;
  background: var(--main-brand-dark);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.RepatchHeader_menuBurger__2my2x::before,
.RepatchHeader_menuBurger__2my2x::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 4px;
  background: var(--main-brand-dark);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.RepatchHeader_menuBurger__2my2x::before {
  -webkit-transform: translate(-20px, -12px);
          transform: translate(-20px, -12px);
}
.RepatchHeader_menuBurger__2my2x::after {
  -webkit-transform: translate(-20px, 12px);
          transform: translate(-20px, 12px);
}
/* ANIMATION */
.RepatchHeader_menu__1K7PR.RepatchHeader_open__1BDEs .RepatchHeader_menuBurger__2my2x {
  -webkit-transform: translateX(-75px);
          transform: translateX(-75px);
  background: transparent;
  box-shadow: none;
}
.RepatchHeader_menu__1K7PR.RepatchHeader_open__1BDEs .RepatchHeader_menuBurger__2my2x::before {
  -webkit-transform: rotate(45deg) translate(35px, -35px);
          transform: rotate(45deg) translate(35px, -35px);
}
.RepatchHeader_menu__1K7PR.RepatchHeader_open__1BDEs .RepatchHeader_menuBurger__2my2x::after {
  -webkit-transform: rotate(-45deg) translate(35px, 35px);
          transform: rotate(-45deg) translate(35px, 35px);
}

.CookiePermission_container__ijZQQ {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 100vh;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 5px;
}

.CookiePermission_column__xbax8 {
  display: flex;
  flex-direction: column;
}

.CookiePermission_closed__3Ipw- {
  max-height: 0vh;
  padding-top: 0px;
}

@media screen and (max-width: 450px) {
  .CookiePermission_container__ijZQQ {
    height: 150px;
  }
}

