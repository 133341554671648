.container {
  padding: 3vh;
  font-size: 0.7em;
  background-color: var(--main-brand-dark);
  color: white;
}

.linksContainer {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
}

.link {
  color: white;
  font-weight: 300;
  font-size: clamp(0.7rem, 1.5vw, 1rem);
  margin: 0 0.25rem;
}
