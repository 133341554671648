.promoContainer {
  width: 80vw;
  max-width: 900px;
  border-radius: 10px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: all 1s ease-in-out;
  background-color: var(--main-brand-dark);
  color: white;
  padding: 0.5rem;
  max-height: 0;
  height: auto;
}

.visible {
  opacity: 1;
  max-height: 1000px;
}

.icon {
  font-size: 10vw;
  max-width: 50px;
  max-height: 50px;
}

.textContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.promoText {
  flex: 1;
  font-size: clamp(1rem, 3vw, 2rem);
}

.codeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5vw;
}

.copyContainer {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin: 0.5rem 0;
}

.promoCode {
  font-weight: 600;
  font-size: clamp(1.4rem, 4vw, 2.5rem);
  color: var(--main-brand-dark);
  text-align: center;
  padding: 0.25rem 1rem;
  border-right: 2px solid var(--main-brand-dark);
}

.copyButton {
  color: var(--main-brand-dark);
  border: none;
  font-size: 1.2rem;
  padding: 0.25rem 1rem;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyButton:hover {
  opacity: 0.5;
}

@media screen and (max-width: 500px) {
  .promoContainer {
    width: 100%;
    border-radius: 0;
  }
}
