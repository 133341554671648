.ticketPageHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.profileButton {
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 10px;
  border: lightgray 1px solid;
  color: rgb(80, 80, 80);
  cursor: pointer;
  transition: all 0.2s linear;
  font-weight: 500;
}

.profileButton:hover {
  background-color: var(--lightgrey);
}

.profilePic {
  margin: -200px auto 0 auto;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-position: center;
  border-radius: 100px;
}

.quotesContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-top: 1rem;
}

.error {
  color: var(--warning-red);
  font-size: 16px;
  margin: 1rem 0;
}

.estimate {
  font-size: clamp(0.8rem, 1.8vw, 1.2rem);
  font-weight: 500;
  margin: 0.5rem 0;
}

.buttonContainer {
  display: flex;
  width: 100%;
}
