.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 5px;
}

.personalContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.paymentContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.termsContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 1rem 0;
}

.paymentForm {
  margin: 1rem;
}

.subtitle {
  font-size: clamp(1rem, 3vw, 2rem);
  margin-bottom: 1rem;
}

.cardForm {
  margin: 2rem 0;
}

.error {
  font-size: 1rem;
  color: var(--warning-red);
}

.closedMessage {
  font-size: clamp(1.2rem, 1.5vw, 1.2rem);
  margin-bottom: 1rem;
}
