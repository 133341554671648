.container {
  background-color: white;
  padding: 1rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  filter: drop-shadow(1px 1px 4px var(--lightgrey));
}

.inputLabel {
  align-self: flex-start;
  text-align: left;
  margin: 1rem 0 1rem 0;
}

.emailPreferences {
  display: flex;
  flex-direction: row;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.checkbox {
  margin-right: 5px;
}

.small {
  font-size: clamp(0.6rem, 1vw, 0.8rem);
}

.error {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }
}
