.container {
  background-color: white;
  padding: 1rem;
  width: 80%;
  max-width: 600px;
}

.title {
  font-size: clamp(1.7rem, 3vw, 2.5rem);
  margin: 1rem 0;
}

.error {
  color: var(--warning-red);
  font-size: 1rem;
  margin: 1rem 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.loginForm {
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  transition: max-height 0.5s ease-in-out;
}

.visible {
  max-height: 1000px;
}

.linkContainer {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
}
