.container {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  background-color: white;
  z-index: 9998;
  overflow: hidden;
}

.logo {
  height: 11vh;
  width: auto;
}

.menuLogo {
  margin-left: -1rem;
  margin-bottom: 10vh;
}

.sideMenu {
  z-index: 9998;
  height: 100vh;
  width: 100vw;
  top: 0;
  max-width: 400px;
  background-color: white;
  position: fixed;
  right: -100vw;
  filter: drop-shadow(0px 1px 2px var(--lightgrey));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}

.sideMenuOpen {
  right: 0;
  padding: 0 1rem;
}

.menuLink {
  margin: 0.5rem 0;
  cursor: pointer;
  text-decoration: none;
}

.menu {
  z-index: 9999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  /* border: 3px solid #fff; */
}
.menuBurger {
  width: 40px;
  height: 4px;
  background: var(--main-brand-dark);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menuBurger::before,
.menuBurger::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 4px;
  background: var(--main-brand-dark);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.menuBurger::before {
  transform: translate(-20px, -12px);
}
.menuBurger::after {
  transform: translate(-20px, 12px);
}
/* ANIMATION */
.menu.open .menuBurger {
  transform: translateX(-75px);
  background: transparent;
  box-shadow: none;
}
.menu.open .menuBurger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu.open .menuBurger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
