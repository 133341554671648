.react-calendar {
  font-family: 'roboto', 'sans-serif';
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: 400;
  padding: 0;
  text-decoration: none;
}

.react-calendar__tile--now {
  background-color: var(--main-brand-light) !important;
}

.react-calendar__tile--active {
  background-color: var(--main-brand-dark) !important;
  color: white;
  font-weight: 600;
}
