.container {
  width: 90%;
  margin: 0 auto 0 auto;
  height: 100px;
  display: flex;
  flex-direction: row;
  display: flex;
  position: relative;
}

.trackContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.infoContainer {
  flex: 7;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 1rem;
}

.track {
  background-color: darkgray;
  height: 100%;
  width: 3px;
}

.trackComplete {
  background-color: #0e47a1;
}

.lozenge {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: darkgray;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.current {
  animation: pulse-blue 2s infinite;
  background-color: #0e47a1;
}

.complete {
  height: 46px;
  width: 46px;
  padding: 5px;
  background-color: #0e47a1;
}

.checkmark {
  height: 100%;
  width: 100%;
}

.title {
  font-size: 0.6em;
  font-weight: bold;
  text-align: start;
}

.subTitle {
  font-size: 0.5em;
  text-align: start;
}

.timeEstimate {
  font-weight: bold;
  color: #8b53ff;
  text-align: start;
  font-size: 0.5em;
}

.estimatePrefix {
  color: var(--main-brand-dark);
  font-weight: 400;
}

.repatched {
  height: 46px;
  width: 46px;
  padding: 5px;
  background-color: green;
}

.linkContainer {
  margin-top: 0.5rem;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 71, 161, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 17px rgba(14, 71, 161, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 71, 161, 0);
  }
}
