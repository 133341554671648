.trackingContainer .whiteContainer {
  width: 100%;
}

.trackingPageHeading {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.trackingPageHeading h2 {
  font-size: 0.8em;
}
.trackingPageHeading p {
  font-size: 0.7em;
}

.taskList {
  margin-top: 1rem;
}
