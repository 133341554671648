.container {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 100vh;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 5px;
}

.column {
  display: flex;
  flex-direction: column;
}

.closed {
  max-height: 0vh;
  padding-top: 0px;
}

@media screen and (max-width: 450px) {
  .container {
    height: 150px;
  }
}
