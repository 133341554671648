.container {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ticket {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.pageTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: 'Assistant';
}

.emptyOrdersContainer {
  margin: 0.5rem;
  border-bottom: 1px dashed #cea2ef;
  padding-bottom: 1rem;
}

.emptyText {
  font-family: 'Assistant';
  color: rgb(71, 71, 71);
  font-size: clamp(18px, 5vw, 24px);
  margin-bottom: 0.5rem;
}

.emptyTextSub {
  color: rgb(129, 129, 129);
  font-size: clamp(14px, 3vw, 20px);
  margin-bottom: 1rem;
}

.emptyOrdersContainer button p {
  font-family: 'Assistant';
  font-weight: 400;
}

.headings {
  opacity: 1;
}

.ticket:hover {
  opacity: 1;
}

.header {
  font-size: clamp(0.7rem, 2vw, 1.1rem);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.text {
  text-decoration: none;
  font-size: clamp(14px, 4vw, 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Assistant';
  margin-bottom: 0.25rem;
}

.orderNumber {
}

textarea {
  padding: 5px;
  height: 10vh;
}
