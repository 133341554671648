.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0;
  z-index: 9999;
  max-height: 0;
  overflow: hidden;
  width: 100vw;
}

.open {
  opacity: 1;
  max-height: 2000px;
  transition: opacity 0.3s ease-in-out;
}

.modalContent {
  min-width: 200px;
  max-width: 600px;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.modalHeader {
  align-self: flex-end;
}

.modalBody {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.closeButton {
  cursor: pointer;
  font-size: clamp(1.5rem, 4vw, 2rem);
}
