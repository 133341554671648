.starRating {
  font-size: 1.5em;
}

.starRating i {
  position: relative;
  color: #ccc;
}
/* create a half-star by nesting a <b> inside an <i> */
.starRating b {
  color: #380064;
  position: absolute;
  left: 0px;
  width: 50%;
  overflow: hidden;
}