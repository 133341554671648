.pickupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickupText {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timePicker {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  display: flex;
  align-items: center;
}
