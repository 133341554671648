.background {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  padding: 1rem;
  max-width: 1000px;
}

.leftContainer {
  margin-right: 0.5rem;
}

.rightContainer {
  margin-left: 0.5rem;
  flex: 1;
}

.buttonContainer {
  filter: drop-shadow(1px 1px 2px var(--lightgrey));
  background-color: white;
  border-radius: 10px;
  margin: 0.5rem 0;
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.buttonContainer:hover {
  background-color: rgb(224, 224, 224);
}

.buttonTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
}

.imageContainer {
  flex: 1;
}

.image {
  width: 100%;
  height: auto;
}

.title {
  font-weight: 500;
  font-size: clamp(1.1rem, 2.2vw, 1.5rem);
}

.subtitle {
  color: var(--darkgrey);
  font-weight: 300;
  font-size: clamp(0.9rem, 1.8vw, 1rem);
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .leftContainer {
    flex: 1;
    margin-right: 0rem;
    order: 1;
    width: 90%;
    max-width: 600px;
  }

  .rightContainer {
    flex: 1;
    margin-left: 0rem;
    order: 0;
    width: 90%;
    max-width: 600px;
    margin-bottom: 1rem;
  }
}
