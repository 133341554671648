.container {
  background-color: white;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  filter: drop-shadow(1px 1px 4px var(--lightgrey));
  min-height: 50vh;
  display: flex;
  height: 100%;
}

.row {
  display: flex;
  align-items: center;
}

.error {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  margin-top: 20px;
  margin-bottom: 20px;
}
.errorHelp {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  margin-top: 0;
  margin-bottom: 5px;
}
.errorHelpLarge {
  color: var(--warning-red);
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  margin-top: 0;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .container {
    width: 90%;
  }
}
