.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: white;
  z-index: 9997;
  border-bottom: 2px solid rgb(207, 207, 207);
}

.searchIcon {
  height: 25px;
  width: 25px;
  margin: 0 0.25rem;
}

.inputContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.locationInput {
  border: none;
  padding: 0.5rem 0.25rem;
  color: var(--darkgrey);
  outline: none;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  transition: all 0.5s linear;
  width: 100%;
}

.locationInput::placeholder {
  color: #d4d4d4;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  background-color: white;
  width: 100%;
  filter: drop-shadow(0px 1px 2px var(--lightgrey));
  max-height: 40vh;
  overflow-y: scroll;
}

.locationResult {
  font-size: 0.7rem;
  cursor: pointer;
  margin: 0.5rem 0;
}

.locationResult:hover {
  text-decoration: underline;
}

.error {
  font-size: 0.7rem;
  color: var(--warning-red);
  margin-top: 0.5rem;
}
