.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}

.discountText {
  font-size: 20px;
}

.removeIcon {
  color: var(--lightgrey);
  cursor: pointer;
  font-size: 20px;
}
