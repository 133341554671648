.container {
  margin: 0.5rem 0;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 0.7rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-brand-dark);
}

.manufacturerContainer {
}

.manufacturerName {
  background-color: rgb(211, 211, 211);
  color: rgb(66, 66, 66);
  padding: 0.5rem 0;
  margin: 0 -0.5rem;
  font-size: clamp(1rem, 1.5vw, 1.1rem);
}

.deviceName {
  color: rgb(66, 66, 66);
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid rgb(211, 211, 211);
  padding: 0.7rem 0;
  text-align: left;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
}

.deviceName:hover {
  background-color: rgb(236, 236, 236);
}

.deviceName:last-child {
  border: none;
}
